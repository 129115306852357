import SystemRepository from '@/repositories/SystemRepository';

export default class SystemService {
  static async versionInfo(): Promise<{ date: string, version: string }> {
    const {data} = await SystemRepository.getVersionInfo();

    return {
      date: data.date as string,
      version: data.version as string,
    };
  }
}
