
import {translateApp} from '@/plugins/i18n';
import TranslationService from '@/services/TranslationService';
import {ILanguage} from '@/types/TranslationTypes';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';

const translations = namespace('translations');

@Component({name: 'LanguageSelect'})
export default class LanguageSelect extends Vue {
  @Prop({default: 'primary'})
  color!: string;

  @translations.State
  languages!: ILanguage[];

  @translations.State
  currentLanguage!: string;

  changeLanguage(language: ILanguage) {
    TranslationService.setCurrentLanguage(language.shortcut);
    this.$vuetify.lang.current = language.shortcut.toLowerCase();
    translateApp();
  }
}
