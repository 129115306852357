
import {Component, Prop, Vue} from 'vue-property-decorator';
import menuItems from '@/constants/MenuItems';
import IMenuItem from '@/types/IMenuItem';
import ISubMenuItem from '@/types/ISubMenuItem';
import {namespace} from 'vuex-class';
import {isPermission} from '@/utils/perrmissions';
import SystemService from '@/services/SystemService';

const layout = namespace('layout');
const user = namespace('user');

const frontEndVersion = `${process.env.VUE_APP_VERSION_MAJOR}.${process.env.VUE_APP_VERSION_MINOR}.${process.env.VUE_APP_VERSION_RELEASE}`;

@Component({
  name: 'Navigation',
})
export default class Navigation extends Vue {
  navbarItems: (IMenuItem | ISubMenuItem)[] = menuItems;

  @Prop({required: true})
  value!: boolean;

  @layout.Getter
  isMobile!: boolean;

  @user.State
  permissions!: string[];

  systemInfo: { date: string, frontEndDate: string | undefined, version: string } = {
    date: '',
    frontEndDate: '',
    version: '',
  };

  get state() {
    return this.value;
  }

  set state(value: boolean) {
    if (this.isMobile) {
      this.$emit('input', value);
    }
  }

  get isMinimized() {
    if (!this.isMobile) {
      return this.value;
    }
    return false;
  }

  isPermission(pagePermissions: string[]): boolean {
    return isPermission(pagePermissions);
  }

  created() {
    this.fetchVersionInfo();
  }

  async fetchVersionInfo() {
    const {
      date,
      version,
    } = await SystemService.versionInfo();

    this.systemInfo.date = date;
    this.systemInfo.version = `${version}-${frontEndVersion}`;
    this.systemInfo.frontEndDate = process.env.VUE_APP_VERSION_DATE;
  }
}
