
import Chart from 'vue-apexcharts';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IDashboardAnalysis} from '@/types/IDashboardTypes';
import {formatNumberWithSpaces} from '@/utils/universalUtils';

@Component({
  name: 'DashboardAnalysisComponent',
  components: {Chart},
})
export default class AnalysisComponent extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  data!: {
    data: IDashboardAnalysis[]
    series: number[],
    labels: string[]
  };

  series: number | string[] = [];

  chartOptions = {
    tooltip: {
      y: {
        formatter: (seriesValue: number) => formatNumberWithSpaces(seriesValue, 2),
      },
    },
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: [],
    legend: {
      position: 'bottom',
      show: false,
    },
  };

  @Watch('data', {deep: true})
  onLabelsChanged() {
    this.setData();
  }

  setData() {
    this.chartOptions = {
      ...this.chartOptions,
      // @ts-ignore
      labels: [...this.data.labels],
    };
    // @ts-ignore
    this.series = this.data.series;
  }
}
