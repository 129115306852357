import DashboardRepository from '@/repositories/DashboardRepository';
import IDashboardFilter from '@/types/IDashboardFilter';
import DashboardAnalysesFactory from '@/factories/DashboardAnalysesFactory';
import {IDashboardAnalysis} from '@/types/IDashboardTypes';
import AnalysisRepository from '@/repositories/AnalysisRepository';

export default class DashboardService {
  static async getMarketplaceData(filters: IDashboardFilter)
    : Promise<{ data: IDashboardAnalysis[], labels: string[], series: number[] }> {
    const {data} = await DashboardRepository.getMarketplaceData(filters);
    const parsedData = data.map((item: any) => DashboardAnalysesFactory.toDashboardAnalysis(item))
      .sort((a: IDashboardAnalysis, b: IDashboardAnalysis) =>
        (a.sumAmount.net < b.sumAmount.net) ? 1 : ((b.sumAmount.net < a.sumAmount.net) ? -1 : 0));
    const labels = parsedData.map((item: any) => item.key);
    const series = parsedData.map((item: any) => item.sumAmount.net);
    return {
      data: parsedData,
      labels,
      series,
    };
  }

  static async getSkuData(filters: IDashboardFilter)
    : Promise<{ data: IDashboardAnalysis[], labels: string[], series: number[] }> {
    const {data} = await DashboardRepository.getSkuData(filters);
    const parsedData = data.map((item: any) => DashboardAnalysesFactory.toDashboardAnalysis(item))
      .sort((a: IDashboardAnalysis, b: IDashboardAnalysis) =>
        (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
    const labels = parsedData.map((item: any) => item.key);
    const series = parsedData.map((item: any) => item.sumAmount.net);
    return {
      data: parsedData,
      labels,
      series,
    };
  }

  static async getFiltersData(): Promise<{ skuList: string[], marketplacesList: string[] }> {
    const {data} = await AnalysisRepository.getFilters();
    return {
      skuList: data.skuList,
      marketplacesList: data.marketplaces.map((item: any) => item.name),
    };
  }
}
