
import {Component, Watch, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import DatePicker from '@/components/DatePicker.vue';
import {requiredRaw as required, selectRequiredRaw} from '@/utils/validationRules';
import ITerm from '@/types/ITerms';
import UserService from '@/services/UserService';
import {resolveError} from '@/utils/notifications';
import {ILanguage} from '@/types/TranslationTypes';
import CompanyService from '@/services/CompanyService';
import AuthService from '@/services/AuthService';
import ICountry from '@/types/ICountry';
import ICompanyInfo from '@/types/ICompanyInfo';

const user = namespace('user');
const Translations = namespace('translations');

@Component({
  name: 'Configuration',
  components: {DatePicker},
})
export default class Configuration extends Vue {

  @user.Getter
  getTermsToAccept!: ITerm[];

  step: any = 1;

  countries: ICountry[] = [];

  loading: boolean = false;

  companyForm: ICompanyInfo = {
    address: {
      addressId: 0,
      companyName: '',
      nip: '',
      address: '',
      city: '',
      countryId: 0,
      postalCode: ''
    },
    params: {
      accessKey: '',
      authToken: '',
      clientSecret: '',
      developerId: '',
      reportDate: '',
      sellerId: '',
      defaultCurrency: ''
    },
    name: '',
    nip: '',
    id: 0
  };
  paymentForm: {
    number: string,
    holderFirstName: string,
    holderLastName: string,
    expirationDate: string,
    cvv: string,
    address: string,
    countryId: number,
    city: string,
    postalCode: string,
    terms: ITerm[]
  } = {
    number: '',
    holderFirstName: '',
    holderLastName: '',
    expirationDate: '',
    cvv: '',

    address: '',
    city: '',
    postalCode: '',
    countryId: 0,

    terms: this.getTermsToAccept
  }

  paymentFormRules = {
    number: [required],
    holder: [required],
    expirationDate: [required],
    cvv: [required],
    address: [required],
    countryId: [selectRequiredRaw],
    city: [required],
    postalCode: [required],
    terms: [required]
  }

  @Translations.State
  currentLanguage!: string;

  @Translations.State
  languages!: ILanguage[];

  @Watch('currentLanguage')
  refreshTerms() {
    this.fetchTerms()
  }

  submitPaymentForm() {
    // @ts-ignore
    if (!this.$refs.paymentForm.validate()) {
      return
    }
  }

  async authorizeSPAPI() {
    this.loading = true;
    try {
      const url = await CompanyService.authorizeSPAPI();
      console.log(url)
      window.location.href = url;
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }

  async authorizeMarketing() {
    this.loading = true;
    try {
      const url = await CompanyService.authorizeMarketing();
      console.log(url)
      window.location.href = url;
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }

  get QueryParams() {
    return this.$route.query;
  }

  mounted() {
    if (!!this.QueryParams.step){
      this.step = this.QueryParams.step;
    }
    this.getActualStep();
    this.getCompanyInfo();
    this.fetchCountries();
    this.fetchTerms()
  }

  async getActualStep() {
    try {
      this.step = await CompanyService.getActualStep(true);
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  async getCompanyInfo() {
    try {
      this.companyForm = await CompanyService.getCompanyInfo();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  async fetchCountries() {
    try {
      const cnt = await AuthService.getRegisterForm();
      this.countries = cnt.sort((a, b) => (a.countryName > b.countryName) ? 1 : -1);
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  async fetchTerms() {
    try {
      const language = this.languages.find((element) => element.shortcut === this.currentLanguage) || this.languages[0]
      this.paymentForm.terms = await UserService.getTermsToAccept(language);
    } catch (e) {
      resolveError(e, 'getTerms');
    }
  }

  async finish() {
    // @ts-ignore
    if (!this.$refs.paymentForm.validate()) {
      return
    }
    this.loading = true
    const api = {
      number: this.paymentForm.number,
      holderFirstName: this.paymentForm.holderFirstName,
      holderLastName: this.paymentForm.holderLastName,
      expirationDate: this.paymentForm.expirationDate,
      cvv: this.paymentForm.cvv,

      address: this.paymentForm.address,
      city: this.paymentForm.city,
      postalCode: this.paymentForm.postalCode,
      countryId: this.paymentForm.countryId,
      terms: this.paymentForm.terms
          .filter(term => term.accepted)
          .map(acceptedTerm => acceptedTerm.termId)
    }
    try {
      const url = await CompanyService.finishConfiguration(api);
      if (url.includes('/dashboard')) {
        // TODO: refresh user, odświeżyć dane użytkownika jak po zalogowaniu jeżeli url zawira słowo "dashboard"
        await AuthService.refreshToken()
        await UserService.getCurrentUser()
        await CompanyService.getActualStep(true)
      }
      window.location.href = url;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false
    }

  }

}
