import {IDashboardAnalysis, IDashboardAnalysisAmount} from '@/types/IDashboardTypes';
import {makeCounter} from '@/utils/universalUtils';

const counter = makeCounter();

export default class DashboardAnalysisFactory {
  static toDashboardAnalysis(data: any): IDashboardAnalysis {
    return {
      id: counter(),
      averageOfAmountPerDay: this.toDashboardAnalysisAmount(data.averageOfAmountPerDay),
      averageOfOrdersPerDay: data.averageOfOrdersPerDay,
      key: data.key,
      quantityOfOrders: data.quantityOfOrders,
      sumAmount: this.toDashboardAnalysisAmount(data.sumAmount),
      sumAmountByCurrency: data.sumAmountByCurrency.map((item: any) => this.toDashboardAnalysisAmount(item)),
    };
  }

  static toDashboardAnalysisAmount(data: any): IDashboardAnalysisAmount {
    return {
      currency: data.currency,
      gross: data.gross,
      net: data.net,
      vat: data.vat,
    };
  }
}
