import axios from 'axios';
import IDashboardFilter from '@/types/IDashboardFilter';

export default class DashboardRepository {
  static getMarketplaceData(filters: IDashboardFilter) {
    return axios.post(`analysis/dashboard/marketplace`, filters);
  }

  static getSkuData(filters: IDashboardFilter) {
    return axios.post(`analysis/dashboard/sku`, filters);
  }
}
