import IMenuItem from '@/types/IMenuItem';
import ISubMenuItem from '@/types/ISubMenuItem';

const menuItems: (IMenuItem | ISubMenuItem)[] = [
  {
    icon: 'mdi-home',
    text: 'SYSTEM_DASHBOARD',
    routeName: 'Home',
    permissions: ['public'],
  },
  {
    icon: 'mdi-office-building',
    text: 'SYSTEM_COMPANY',
    routeName: 'Company',
    permissions: ['company.view'],
  },
  {
    icon: 'mdi-file',
    text: 'SYSTEM_TAX_RETURNS',
    routeName: 'TaxDeclarations',
    permissions: ['tax_declaration.view'],
  },
  {
    icon: 'mdi-currency-usd',
    text: 'SYSTEM_EXTERNAL_INCOMES',
    routeName: 'ExternalIncomes',
    permissions: ['external_income.view'],
  },
  {
    icon: 'mdi-account-group',
    text: 'SYSTEM_USERS',
    routeName: 'Users',
    permissions: ['admin.user_view'],
  },
  {
    icon: 'mdi-city-variant',
    text: 'SYSTEM_COMPANIES',
    routeName: 'Companies',
    permissions: ['admin.company_view'],
  },
  {
    icon: 'mdi-chart-bar',
    text: 'SYSTEM_ANALYSES',
    routeName: 'Analyses',
    permissions: ['analysis.view'],
  },
  {
    icon: 'mdi-file-document',
    text: 'SYSTEM_REPORTS',
    routeName: 'Reports',
    permissions: ['report.view'],
  },
  {
    icon: 'mdi-google-translate',
    text: 'SYSTEM_TRANSLATIONS',
    routeName: 'Translations',
    permissions: ['admin.translation'],
  },
  //  TEST api - do usunięcia
  // {
  //   icon: 'mdi-home',
  //   text: 'Test API',
  //   routeName: 'Authorize',
  //   permissions: ['public'],
  // },
  // { icon: 'mdi-history', text: 'About', routeName: 'About' },
  // { icon: 'mdi-content-copy', text: 'Duplicates' },
  // {
  //   icon: 'mdi-chevron-up',
  //   'icon-alt': 'mdi-chevron-down',
  //   text: 'Labels',
  //   model: true,
  //   children: [
  //     {
  //       icon: 'mdi-plus',
  //       text: 'Create label',
  //       routeName: 'About'
  //     }
  //   ],
  // },
  // { icon: 'mdi-cog', text: 'Settings' },
  // { icon: 'mdi-message', text: 'Send feedback' },
  // { icon: 'mdi-help-circle', text: 'Help' },
  // { icon: 'mdi-cellphone-link', text: 'App downloads' },
  // { icon: 'mdi-keyboard', text: 'Go to the old version' },
];

export default menuItems;
