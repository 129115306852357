

import {Component, Vue} from 'vue-property-decorator';
import axios from "axios";

@Component({
  name: 'Authorize'
})
export default class Authorize extends Vue {

  private loginUrl: string = 'https://sellercentral.amazon.com/apps/authorize/consent?';
  private authUrl: string = 'https://api.amazon.com/auth/o2/token';
  private redirectUrl: string = 'https://api.krosnoglass.dev.aberit.eu/authorize';

  private clientId = 'amzn1.application-oa2-client.74d0fed030af4de8a4a7f4c4d2417f8a';
  private clientSecret = 'c984af5af0da0c7c09aabe34c255f652d322b1112807b2d4f846267061fe5359';

  private queryParams = {};
  private data = {};

  private selling_partner_id: any = '';
  private mws_auth_token: any = '';
  private spapi_oauth_code: any = '';

  step0() {
    window.location.href = `${this.loginUrl}?application_id=amzn1.sp.solution.9eb91c51-f1cd-442d-81cd-ad2dbb7a8ad3&version=beta&redirect_uri=${this.redirectUrl}`;
  }

  mounted() {
    const queryParams = this.QueryParams;
    this.selling_partner_id = queryParams.selling_partner_id;
    this.mws_auth_token = queryParams.mws_auth_token;
    this.spapi_oauth_code = queryParams.spapi_oauth_code;
    console.log(queryParams);
  }

  async step1() {
    const url = `${this.authUrl}?grant_type=authorization_code&code=${this.spapi_oauth_code}&redirect_uri=${this.redirectUrl}&client_id=${this.clientId}&client_secret=${this.clientSecret}`;
    this.data = axios.post(url);
    console.log(this.data);
  }

  get QueryParams() {
    return this.$route.query;
  }

}
