
import {Component, Emit, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import AuthService from '@/services/AuthService';
import CompaniesService from '@/services/CompaniesService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import LanguageSelect from '@/components/LanguageSelect.vue';

const user = namespace('user');

@Component({
  name: 'Header',
  components: {LanguageSelect},
})
export default class Header extends Vue {
  @user.State('isLoggedAs')
  isLoggedAs!: boolean;

  @user.State('companyName')
  companyName!: string;

  @user.Getter('fullName')
  fullName!: string;

  logoutLoading: boolean = false;

  @Emit('toggleNavigation')
  toggleNavigation() {
    //
  }

  logout() {
    AuthService.logout();
  }

  async logoutAs() {
    try {
      this.logoutLoading = true;
      await CompaniesService.logoutAs();
      await this.$router.push({name: 'Home'}).catch(() => {
        //
      });
      notify(NotificationTypes.success, this.$t('SYSTEM_LOGGED_OUT'));
    } catch (e) {
      resolveError(e, 'logout');
    } finally {
      this.logoutLoading = false;
    }
  }
}
