

import {Component, Vue} from 'vue-property-decorator';
import DashboardService from '@/services/DashboardService';
import IDashboardFilter from '@/types/IDashboardFilter';
import {IDashboardAnalysis} from '@/types/IDashboardTypes';
import {dateToYYYYmmDD, startMonthDate, subtractMonths} from '@/utils/dateTime';
import {resolveError} from '@/utils/notifications';
import {isPermission} from '@/utils/perrmissions';
import DashboardAnalysisComponent from '@/components/Dashboard/AnalysisComponent.vue';

@Component({
  name: 'Dashboard',
  components: {
    DashboardAnalysisFilters: () => import('@/components/Dashboard/DashboardAnalysisFilters.vue'),
    DashboardAnalysisTable: () => import('@/components/Dashboard/DashboardAnalysisTable.vue'),
    DashboardAnalysisComponent,
  },
})
export default class Dashboard extends Vue {
  marketplaceFilters: IDashboardFilter = {
    dateFrom: startMonthDate(subtractMonths(new Date(), 3)),
    dateTo: dateToYYYYmmDD(new Date()),
    keys: [],
  };
  skuFilters: IDashboardFilter = {
    dateFrom: startMonthDate(subtractMonths(new Date(), 3)),
    dateTo: dateToYYYYmmDD(new Date()),
    keys: [],
  };

  skuList: string[] = [];
  marketplacesList: string[] = [];

  marketplaceDataLoading: boolean = false;
  skuDataLoading: boolean = false;

  marketplaceData: {
    data: IDashboardAnalysis[]
    series: number[],
    labels: string[]
  } = {
    data: [],
    series: [],
    labels: [],
  };
  skuData: {
    data: IDashboardAnalysis[]
    series: number[],
    labels: string[]
  } = {
    data: [],
    series: [],
    labels: [],
  };

  created() {
    if (this.isPermission(['dashboard.view'])) {
      this.fetchFiltersData();
      this.fetchMarketplaceData();
      this.fetchSkuData();
    }
  }

  async fetchFiltersData() {
    try {
      const {
        skuList,
        marketplacesList,
      } = await DashboardService.getFiltersData();

      this.marketplacesList = marketplacesList;
      this.skuList = skuList;
    } catch (e) {
      resolveError(e, 'fetch_filters');
    }
  }

  async fetchMarketplaceData() {
    try {
      this.marketplaceDataLoading = true;
      const {
        data,
        labels,
        series,
      } = await DashboardService.getMarketplaceData(this.marketplaceFilters);
      this.marketplaceData.data = data;
      this.marketplaceData.labels = labels;
      this.marketplaceData.series = series;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.marketplaceDataLoading = false;
    }
  }

  async fetchSkuData() {
    try {
      this.skuDataLoading = true;
      const {
        data,
        labels,
        series,
      } = await DashboardService.getSkuData(this.skuFilters);
      this.skuData.data = data;
      this.skuData.labels = labels;
      this.skuData.series = series;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.skuDataLoading = false;
    }
  }

  isPermission(permissions: string[]): boolean {
    return isPermission(permissions);
  }
}
